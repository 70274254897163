<template>
    <CCard class="project-scopes-list">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active> Scopes</CBreadcrumbItem>
        </teleport>

        <CCardBody class="project-scopes-list__body">
            <ConfirmDialog ref="confirmDialog"/>

            <CFormSelect v-model="approval" class="project-scopes-list__approval" :options="approvalOptions"/>

            <SmartTable class="project-scopes-list__table" :items="scopesList" :items-per-page="parseInt(perPage)"
                        :columns="scopesTableTemplate" :sorter-value="sort" :loading="loadingTable"
                        :active-page="activePage" :per-page="perPage" :pages="pages" @sorter-change="onSort"
                        @update-active-page="(value) => (activePage = value)"
                        @update-per-page="(value) => (perPage = value)">

                <template #scope_name="{ item }">
                    <COverdueTooltip v-if="item.is_overdue" :overdue-date="$BtoF({ value: item.due_date })"
                                     style="margin-right: 8px"/>
                    <a href="#" @click="edit(item)">
                        {{ item.scope_name }}
                    </a>
                </template>

                <template #scope_status="{ item }">
                    <TaskStatusBadge v-if="item.scope_status === 'contract sent' && item.contract_task"
                                     :task="item.contract_task" size="md"/>
                    <CBadge v-else :color="item.scope_status === 'pending' ? 'primary' : 'success'">
                        {{ item.scope_status }}
                    </CBadge>
                </template>

                <template #button="{ item }">
                    <td>
                        <CIcon name="cil-cloud-upload" class="pointer" @click="upload(item)"/>
                        <CIcon name="cil-pencil" class="pointer ml-2" @click="edit(item)"/>
                        <CIcon name="cil-trash" class="pointer ml-2" @click="deleteScope(item)"/>
                    </td>
                </template>

                <template #clear_text>
                    <div class="project-scopes-list__table-clear">
                        <h2 class="project-scopes-list__table-heading">
                            There are no scopes for this project yet!
                        </h2>
                        <p class="project-scopes-list__table-subtitle">
                            But we are almost there! Let’s add the new scope to this project
                        </p>
                        <img class="project-scopes-list__table-image" src="@/assets/images/clear-text.jpg"/>
                        <CButton class="project-scopes-list__table-button" color="primary" @click="openNewScopePopup">
                            Add scope
                        </CButton>
                    </div>
                </template>
            </SmartTable>
        </CCardBody>
    </CCard>
</template>

<script>
import table from '@/mixin/table'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan} from '@/services/Notify/Toasts'
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";

export default {
    name: 'Index',
    components: {TaskStatusBadge, ConfirmDialog},
    mixins: [table],
    inject: ['toast', 'mountedComponent'],
    data() {
        return {
            approval: 'all',
            approvalOptions: [
                {value: 'all', label: 'All'},
                {value: 'assigned', label: 'Assigned'},
                {value: 'not_assigned', label: 'Not assigned'},
            ],
            scopesList: [],
            scopesTableTemplate: [
                {
                    key: 'scope_name',
                    label: 'Scope Name',
                    _style: '',
                },
                {
                    key: 'company_name',
                    label: 'Company Name',
                    _style: '',
                    link: {name: 'CompanyInfo', params: {id: 'company_id'}},
                },
                {
                    key: 'scope_contract_type',
                    label: 'Assigned Contract Folder',
                    _style: '',
                },
                {
                    key: 'scope_status',
                    label: 'Scope Status',
                },
                {
                    key: 'task_status',
                    label: 'Task Assigment Status',
                    _style: '',
                    format: (value) => value?.capitalize(),
                },
                {
                    key: 'scope_amount',
                    label: 'Amount',
                    _style: '',
                    format: (value) =>
                        this.$currency(value, {
                            separator: ',',
                            pattern: `! #`,
                        }).format(),
                },
            ],
        }
    },
    watch: {
        approval() {
            this.fetchData()
        },
    },
    methods: {
        async fetchData() {
            this.loadingTable = true
            await this.$http.scope
                .fetchScopes({
                    params: {
                        project_id: this.$route.params.id,
                        per_page: this.perPage,
                        page: this.activePage,
                        task_status: this.approval,
                        sort_field: this.sort && this.sort.column,
                        sort_direction: this.sort && this.sort.state,
                    },
                })
                .then(({data}) => {
                    this.scopesList = data.data
                    this.pages = data.meta.last_page
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: 'PermissionsError'})
                    }
                })
                .finally(() => {
                    this.loadingTable = false
                })
        },
        async edit(scope) {
            if (await Gate.can('view', 'scope', scope.id))
                this.$router.push({
                    name: 'ProjectScopeEdit',
                    params: {
                        id: this.$route.params.id,
                        scopeId: scope.id,
                    },
                })
        },
        async upload(scope) {
            if (await Gate.can('view', 'scope', scope.id))
                this.$router.push({
                    name: 'ProjectScopeUpload',
                    params: {
                        id: this.$route.params.id,
                        scopeId: scope.id,
                    },
                })
        },
        async deleteScope(scope) {
            const check = await Gate.can('delete', 'scope', scope.id)
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete scope ${scope?.scope_name}? All the related tasks will be deleted.`,
                    cancelText: 'Cancel',
                    confirmText: 'Yes, delete',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.scope.deleteScope(scope.id).then(({data}) => {
                            this.toast('info', data.message)
                            this.fetchData()
                        })
                    }
                })
        },
        async openNewScopePopup() {
            const check = await Gate.can('scopeCreate', 'project', this.$route.params.id)

            if (!check) {
                notifyByPlan()
                return false
            }

            this.$EventBus.emit('openNewScopePopup')
        },
    },
}
</script>

<style lang="scss">
@import 'style';
</style>
